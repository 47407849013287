import React from "react";
import legalDocumentStyles from "../LegalDocuments.module.css";

import Wrapper from "../../UI/Wrapper/Wrapper";

const PrivacyPolicy = () => (
  <Wrapper>
    <div className={legalDocumentStyles.LegalDocuments}>
      <h1>IZJAVA O PRIVATNOSTI</h1>
      <div>
        <ol type="I">
          <li className={legalDocumentStyles.Headers}>KO SMO MI</li>
          <ol type="1">
            <li>
              <p>
                Privredno društvo{" "}
                <strong>Extra Care Group d.o.o. Beograd-Zemun</strong> je IT
                kompanija registrovana u Agenciji za privredne registre sa
                sedištem na adresi Dobanovačka 2, pod matičnim brojem 20990376,
                koja se bavi računarskim programiranjem i između ostalog je
                osmislila i dizajnirala mobilnu i web aplikaciju pod nazivom
                Apoteka.rs (u daljem tekstu zajedno: Platforma). Apoteka.rs je
                informativni servis. Partneri pružaju usluge iz delatnosti za
                koju su registrovani, u skladu sa zakonom i regulativom svoje
                delatnosti. Apoteka.rs omogućava komunikaciju i povezivanje
                korisnika sa ovlašćenim pružaocima usluga.
              </p>
            </li>
            <li>
              <p>
                Kada pristupate ili koristite našu Platformu, Extra Care Group
                obrađuje Vaše podatke o ličnosti. Naši kontakt podaci su:
                <span style={{ textAlign: "center" }}>
                  {" "}
                  <strong>Extra Care Group d.o.o. Beograd-Zemun</strong>
                  Dobanovačka 2, 11000 Beograd
                  <br />
                  Telefon: 0800 800 700 (ponedeljak-petak,09:00h – 17:00h)
                  <br />
                  E-mail: dpo@apoteka.rs
                  <br />
                </span>
              </p>
            </li>
            <li>
              <p>
                S obzirom na to da Extra Care Group uvažava i poštuje Vašu
                privatnost i posvećena je zaštiti podataka o ličnosti, ova
                Izjava o privatnosti se primenjuje na sve podatke o ličnosti
                koje obrađujemo prilikom korišćenja i održavanja Platforme.{" "}
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            SVRHA IZJAVE O PRIVATNOSTI
          </li>
          <ol type="1">
            <li>
              <p>
                Ova Izjava o privatnosti ima za cilj da Vam pruži informacije o
                tome kako obrađujemo, odnosno kako prikupljamo, koristimo,
                otkrivamo, prenosimo i skladištimo Vaše podatke o ličnosti kada
                koristite našu Platformu.
              </p>
            </li>
            <li>
              <p>
                Ova Izjava o privatnosti Vam je važna kako biste mogli unapred
                da odredite obim i posledice obrade podataka, te Vam stoga
                preporučujemo da je pažljivo pročitate i informišete se o obradi
                i zaštiti vaših podataka o ličnosti.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>REČNIK POJMOVA</li>
          <ol type="1">
            <li>
              <p>
                Za potrebe ove Izjave o privatnosti, izrazi koji se koriste u
                daljem tekstu imaju sledeće značenje:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>"podaci o ličnosti"</strong> označavaju sve
                    informacije koje se odnose na identifikovanu osobu ili osobu
                    koju je moguće identifikovati, neposredno ili posredno;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"anonimizovani podaci"</strong> jesu podaci na
                    osnovu kojih se lice ne može identifikovati niti na bilo
                    koji drugi način odrediti;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"tehnički podaci"</strong> jesu podaci kao što su
                    adresa internet protokola (IP), vrsta i verzija pregledača,
                    radnje i obrasci pregledanja veb sajta, podešavanje jezika,
                    lokacija uređaja, vremenska zona, vrste i verzije dodataka
                    za pregledač, informacije o operativnom sistemu i drugim
                    tehnologijama uređaja koje koristite za pristupanje
                    Platformi, verzija aplikacije Apoteka.rs;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"podaci o upotrebi"</strong> su informacije o tome
                    kako koristite Platformu, odnosno informacije o tome kako je
                    Vaš uređaj povezan sa našom Platformom, uključujući stranice
                    kojima pristupate i linkove koje otvarate;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"obrada podataka o ličnosti"</strong> jeste svaka
                    radnja ili skup radnji koje se vrše automatizovano ili
                    neautomatizovano sa podacima o ličnosti ili njihovim
                    skupovima, kao što su prikupljanje, beleženje,
                    razvrstavanje, grupisanje, odnosno strukturisanje,
                    pohranjivanje, upodobljavanje ili menjanje, otkrivanje,
                    uvid, upotreba, otkrivanje prenosom, odnosno dostavljanjem,
                    umnožavanje, širenje ili na drugi način činjenje dostupnim,
                    upoređivanje, ograničavanje, brisanje ili uništavanje;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"rukovalac"</strong> je fizičko ili pravno lice,
                    odnosno organ vlasti koji samostalno ili zajedno sa drugima
                    određuje svrhu i način obrade i koji je odgovoran za obradu
                    Vaših podataka o ličnosti;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"obrađivač"</strong> je fizičko ili pravno lice,
                    odnosno organ vlasti koji obrađuje podatke o ličnosti u ime
                    rukovaoca;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      "propisi iz oblasti zaštite podataka o ličnosti"
                    </strong>{" "}
                    jesu važeći Zakon o zaštiti podataka o ličnosti sa
                    podzakonskim aktima;
                  </p>
                </li>
                <li>
                  <p>
                    <strong>"Poverenik"</strong> je Poverenik za informacije od
                    javnog značaja i zaštitu podataka o ličnosti, kao telo
                    nadležno da vrši nadzor nad sprovođenje propisa iz oblasti
                    zaštite podataka o ličnosti.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Svi izrazi koji nisu ovde posebno navedeni, a pominju se u
                nastavku, imaju isto značenje kao i u našim Opštim uslovima
                korišćenja ili u propisima iz oblasti zaštite podataka o
                ličnosti.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            PODACI O LIČNOSTI KOJE OBRAĐUJEMO, SVRHA ZBOG KOJE IH OBRAĐUJEMO
            (ZAŠTO IH OBRAĐUJEMO) I PO KOM PRAVNOM OSNOVU (NA OSNOVU ČEGA IH
            OBRAĐUJEMO)
          </li>
          <ol type="1">
            <li>
              <p>
                Kada pristupite našoj Platformi, obrađujemo Vaše podatke o
                ličnosti, u različite svrhe, po različitim pravnim osnovima, sve
                kako je dalje detaljno objašnjeno.
              </p>
            </li>
            <div className={legalDocumentStyles.TableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>Svrha obrade</th>
                    <th>Podaci koji se obrađuju</th>
                    <th>Pravni osnov obrade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Registracija Korisnika</td>
                    <td>
                      Korisničko ime, odnosno adresa elektronske pošte, lozinka i
                      broj mobilnog telefona
                    </td>
                    <td>
                      Preduzimanje radnji, na zahtev lica na koje se podaci
                      odnose, pre zaključenja ugovora (član 12. stav 1. tačka 2)
                      Zakona)
                    </td>
                  </tr>
                  <tr>
                    <td>Uredno izvršenje usluga</td>
                    <td>
                      Korisničko ime, odnosno adresa elektronske pošte i istorija
                      porudžbina
                    </td>
                    <td>
                      Izvršenje ugovora zaključenog sa licem na koje se podaci
                      odnose (član 12. stav 1. tačka 2) Zakona)
                    </td>
                  </tr>
                  <tr>
                    <td>Olakšano upravljanje korisničkim nalogom</td>
                    <td>Broj mobilnog telefona</td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Slanje Newsletter-a</td>
                    <td>Adresa elektronske pošte</td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Pružanje dodatnih pogodnosti Platforme</td>
                    <td>Podatak o geolokaciji</td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Omogućavanje benefita iz Loyalty programa</td>
                    <td>
                      Podaci o uspešno preuzetim rezervacijama koje sadrže
                      proizvode koje su deo Loyalty programa i podaci o
                      iskorišćenih benefitima iz Loyalty programa
                    </td>
                    <td>Legitimni interes (član 12. stav 1. tačka 6) Zakona)</td>
                  </tr>
                  <tr>
                    <td>
                      Pojednostavljena provera dostupnosti određene terapije
                    </td>
                    <td>JMBG</td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>
                      Poboljšanje funkcionalnosti Platforme i korisničkog iskustva
                    </td>
                    <td>
                      Tehnički podaci, podaci sa uređaja i podaci o upotrebi
                    </td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Statistika</td>
                    <td>
                      Tehnički podaci, podaci sa uređaja i podaci o upotrebi
                    </td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Marketing</td>
                    <td>
                      Tehnički podaci, podaci sa uređaja i podaci o upotrebi
                    </td>
                    <td>Pristanak (član 12. stav 1. tačka 1) Zakona)</td>
                  </tr>
                  <tr>
                    <td>Upravljanje i zaštita Platforme</td>
                    <td>
                      Tehnički podaci, podaci sa uređaja i podaci o upotrebi
                    </td>
                    <td>Legitimni interes (član 12. stav 1. tačka 6) Zakona)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4>Podaci o ličnosti koje nam sami dostavite</h4>
            <li>
              <p>
                Podatke koje nam dostavite i to korisničko ime, odnosno adresu
                elektronske pošte, lozinku i Vaš broj mobilnog telefona,
                koristimo za potrebe registracije, a kako bismo mogli da Vam
                pružimo tražene usluge preko naše Platforme. Naše Opšte uslove
                korišćenja Platforme možete pogledati klikom na sledeći link -{" "}
                <a href="https://www.apoteka.rs/TermsOfService">
                  https://www.apoteka.rs/TermsOfService
                </a>
              </p>
            </li>
            <li>
              <p>
                Kada se registrujete i otvorite Vaš korisnički nalog, podatke
                koji se nalaze na Vašem profilu i to korisničko ime, odnosno
                adresa elektronske pošte, lozinka, kao i istorija Vaših
                porudžbina, obrađujemo u cilju izvršenja ugovora, odnosno
                adekvatnog pružanja usluga preko Platforme.
              </p>
            </li>
            <li>
              <p>
                Ukoliko ste saglasni da Platforma zapamti Vaš broj mobilnog
                telefona kako ne biste morali isti da unosite svaki put kada
                naručujete proizvod, isti će se takođe nalaziti na Vašem
                profilu, a taj podatak čuvamo i obrađujemo na osnovu Vašeg
                pristanka.
              </p>
            </li>
            <li>
              <p>
                Ukoliko želite da proverite dostupnost određene terapije kod
                naših Partnera možete uneti Vaš JMBG, te ukoliko ne želite isti
                svaki put da unosite taj broj u istu svrhu i možete dati
                pristanak da Platforma zapamti Vaš JMBG, koji će se u tom
                slučaju isto nalaziti na Vašem profilu.
              </p>
            </li>
            <li>
              <p>
                Takođe, ukoliko ste saglasni, možemo da koristimo Vašu adresu
                elektronske pošte kako bismo Vam slali obaveštenja o novostima
                na našoj Platformi, o našim partnerima ili njihovim proizvodima,
                o kretanjima, odnosno fluktuaciji proizvoda, eventualnoj budućoj
                nestašici određenog proizvoda i slično, što Vam može biti
                posebno korisno ukoliko redovno uzimate ili koristite određeni
                proizvod.{" "}
              </p>
            </li>
            <li>
              <p>
                Podatak o geolokaciji obrađujemo isključivo ukoliko nam date
                saglasnost za tako nešto, kako bismo Vam prikazali koje apoteke
                su vam najbliže. U tom slučaju, Platforma koristi alat Google
                maps kompanije Google, pronalazi vašu geolokaciju, kako bi mogla
                da Vam pruži željene podatke, pri čemu nakon što isključite
                lokaciju ili izađete sa naše internet stranice, Extra Care Group
                takav podatak ne čuva.
              </p>
            </li>
            <li>
              <p>
                Kako bismo poboljšali funkcionalnosti i Vaše korisničko iskustvo
                na našoj Platformi, koristimo podatke kao što su adresa internet
                protokola (IP), vrsta i verzija pregledača, radnje i obrasci
                pregledanja veb sajta i slično.
              </p>
            </li>
            <li>
              <p>
                Za svrhu statistike, koristimo zbirne podatke, kao što su
                statistički ili demografski podaci koji ne otkrivaju direktno
                ili indirektno Vaš identitet. Na primer, možemo objediniti vaše
                podatke o upotrebi da bismo izračunali procenat korisnika koji
                pristupaju određenoj uslužnoj funkciji, odnosno koliki je bio
                ukupan broj poseta Platformi na dnevnom, nedeljnom ili mesečnom
                nivou, broj posetilaca po teritorijama i slično. Statistički
                podaci su anonimizovani podaci.
              </p>
            </li>
            <li>
              <p>
                U svrhu upravljanja i zaštite naše usluge, uključujući rešavanje
                problema, testiranje, održavanje sistema, podršku, sprečavanje
                zloupotreba, koristimo Vaše tehničke podatke, podatke sa uređaja
                i podatke o upotrebi Platforme. Isto vršimo na osnovu legitimnog
                interesa koji se ogleda u vidu sigurne trgovine i bezbednosnog
                funkcionisanja Platforme, što je nama od suštinskog značaja, a i
                Vama kao Korisnicima koji koristite našu Platformu. Legitimni
                interes, uključujući i neophodnost i srazmernost obrade je
                detaljno obrazložena u dokumentu "Procena legitimnog interesa –
                upravljanje i zaštita usluga preko Pratforme Apoteka.rs".
              </p>
            </li>
            <h4>Podaci o ličnosti koje prikupljamo automatski</h4>
            <li>
              <p>
                Kada koristite Platformu, određene podatke o ličnosti dobijamo
                automatski, kao što su IP adresa uređaja koji koristite za
                povezivanje na Internet i stranice kojima pristupate.{" "}
              </p>
            </li>
            <li>
              <p>
                Naša veb stranica koristi "kolačiće" i "veb svetionike" za
                optimizaciju funkcionalnosti i pružanje najboljeg mogućeg
                iskustva. Više o kolačićima, odnosno šta isti predstavljaju,
                kako ih koristimo i zašto, uključujući i marketinške kolačiće,
                možete pogledati u delu našeg veb sajta koji se odnosi na
                kolačiće. Veb svetionici se koriste u kombinaciji sa kolačićima
                za merenje aktivnosti posetioca na veb sajtovima. Možemo
                koristiti veb svetionike za dobijanje informacija o URL adresi
                na stranici na kojoj se svetionik pojavljuje, trajanju pregleda
                stranice koja sadrži svetionik, kao i vrsti pregledača koji se
                koristi za pregled stranice.
              </p>
            </li>
            <li>
              <p>
                Takođe, ukoliko koristite našu izvornu mobilnu aplikaciju,
                bićete upitani da li želite da primate push obaveštenja od
                platforme. Push obaveštenja mogu uključivati upozorenja,
                zvukove, bedževe ikona i druge informacije u vezi sa korišćenjem
                same usluge. Možete izabrati da dozvolite ili odbijete slanje
                push obaveštenja na Vaš uređaj, odnosno slaćemo Vam push
                obaveštenje isključivo ukoliko odaberete takvu opciju na Vašem
                uređaju. Ako ne dozvolite da Vam šaljemo push obaveštenja, moći
                ćete da koristite mobilnu aplikaciju, ali možda nećete moći u
                potpunosti da iskoristite njene prednosti. Isključivo možete da
                kontrolišete svoje postavke za primanje push obaveštenja u
                okviru podešavanja svog uređaja.
              </p>
            </li>
            <h4>Podaci koje prikupljamo od strane trećih lica</h4>
            <li>
              <p>
                Ukoliko izaberete opciju da se prijavite na Platformu putem svog
                Google naloga, Vaš podatak o imejl adresi, lozinci i kontakt
                telefonu pribavljamo od kompanije Google LLC.{" "}
              </p>
            </li>
            <li>
              <p>
                U tom slučaju podaci sa Vašeg Google naloga predstavljaju samo
                izvor podataka, kako ne biste morali sami da unosite podatke.{" "}
              </p>
            </li>
            <li>
              <p>
                Navedeni podaci su nam potrebni za Vašu registraciju, a takva
                obrada je neophodna za preduzimanje radnji na zahtev lica pre
                zaključenja ugovora, sve kako je navedeno pod tačkom 4.2. ove
                Izjave o privatnosti.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            OBAVEZNOST DAVANJA PODATAKA O LIČNOSTI
          </li>
          <ol type="1">
            <li>
              <p>
                Davanje Vaših podataka o ličnosti u svrhu registracije i
                korišćenja Platforme je obavezno ukoliko želite da postanete
                korisnik Platforme i koristite sve njene funkcionalnosti. U
                slučaju nedavanja podataka, Extra Care Group neće biti u
                mogućnosti da Vam pruži usluge koje su moguće samo za
                registrovane Korisnike.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            PRISTANAK I OPOZIV PRISTANKA
          </li>
          <ol type="1">
            <li>
              <p>
                Kada obrađujemo Vaše podatke o ličnosti na osnovu pristanka,
                davanje podataka o ličnosti za navedene svrhe je potpuno
                dobrovoljno, ne predstavlja zakonski ili ugovorni uslov, niti je
                preduslov za korišćenje Platforme, već davanjem istog možete
                imati dodatne benefite od korišćenja Platforme.{" "}
              </p>
            </li>
            <li>
              <p>
                Pristanak koji ste dali možete povući u bilo kom trenutku i isto
                možete učiniti odabirom opcije da više ne želite da obrađujemo
                Vaše podatke u navedene svrhe.
              </p>
            </li>
            <li>
              <p>
                U svakom slučaju, pristanak možete opozvati i podnošenjem
                zahteva za opoziv putem e-mail adrese: dpo@apoteka.rs, lično u
                našim prostorijama i putem pošte na adresu sedišta Extra Care
                Group.{" "}
              </p>
            </li>
            <li>
              <p>
                Ukazujemo da opoziv pristanka ne utiče na dopuštenost obrade
                koja je vršena na osnovu pristanka pre opoziva.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            PODATKE KOJE OBRAĐUJEMO KAO OBRAĐIVAČ
          </li>
          <ol type="1">
            <li>
              <p>
                Kada vršite upit, rezervaciju ili kupujete proizvod preko
                Platforme, mi nismo strana u ugovoru između Korisnika i lica
                koje nudi proizvod, iz kog razloga za obradu u navedenu svrhu,
                Extra Care Group postupa kao Obrađivač u ime i po nalogu lica
                koje nudi proizvod, na osnovu zaključenog ugovora o obradi
                podataka o ličnosti.{" "}
              </p>
            </li>
            <li>
              <p>
                Napominjemo da u cilju minimizacije podataka i primene
                adekvatnih mera zaštite, nakon što je kupoprodaja poručenog
                proizvoda realizovana, lice koje Vam je isporučilo proizvod,
                nema više vidljive Vaše podatke o ličnosti preko Platforme i
                dalju obradu podataka o ličnosti vrši na osnovu propisa kojima
                je uređena njihova osnovna delatnost.{" "}
              </p>
            </li>
            <li>
              <p>
                Takođe, informacije koje dobijate putem Platforme od našim
                Partnera ili drugih lica, a koje mogu sadržati i Vaše podatke,
                Platforma ne obrađuje kao rukovalac, s obzirom da Vam samo
                omogućava olakšanu komunikaciju sa tim licima, odnosno služi kao
                jednostavan kanal komunikacije, dok su ta lica rukovaoci Vašim
                podacima.
              </p>
            </li>
            <li>
              <p>
                Najzad, kada su u pitanju članovi Udruženja "Zdravo svima",
                kojima Udruženje želi da pruži dodatne benefite, pomoć i usluge
                koje inače nemaju svi korisnici Platforme, odnosno da im omogući
                međusobno povezivanje i umrežavanje kako između sebe, tako i sa
                drugim fizičkim i pravnim licima i organima javne vlasti koji se
                zalažu za digitalizaciju u zdravstvu i bolje uslove za njegove
                članove, kao i da omogući određene funkcionalnosti na zahtev
                Udruženja, Platforma isključivo postupa kao Obrađivač, odnosno
                obrađuje podatke o ličnosti po nalogu i u ime Udruženja, kao
                rukovaoca, a sve kako je detaljnije objašnjeno u našim Opštim
                uslovima korišćenja. Sa druge strane, ukoliko članovi Udruženja
                "Zdravo svima" koriste funkcionalnosti Platforme koje su
                dostupne svima, a ne samo članovima Udruženja, Platforma postupa
                kao Rukovalac, sve kako je objašnjeno u ovoj Izjavi o
                privatnosti.
              </p>
            </li>
            <li>
              <p>
                Radi pojašnjenja gore navedenog, umesto da fizički idete u
                apoteku ili zovete telefonom kako biste proverili da li je
                određeni proizvod na stanju ili da rezervišete proizvod ili
                umesto da morate da idete u sedište Udruženja ili neko treće
                mesto da biste se našli sa drugim članovima Vašeg Udruženja,
                Platforma Vam omogućava da to sve uradite iz udobnosti Vašeg
                doma. Dakle, Platforma po pitanju ovih obrada nema svoju svrhu
                niti je odredila način, samo je omogućila sredstvo preko kojeg
                navedeno možete jednostavnim načinom da obavite, a rukovaoci su
                pružaoci usluga, odnosno prodavci proizvoda ili već u slučaju
                članova Udruženja, samo Udruženje koje je odabralo našu
                Platformu za pružanju ovih mogućnosti i benefita svojim
                članovima.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            KO SU PRIMAOCI PODATAKA O LIČNOSTI (SA KIM DELIMO VAŠE PODATKE O
            LIČNOSTI)
          </li>
          <ol type="1">
            <li>
              <p>
                Najpre želimo da Vas obavestimo da ne prodajemo niti na bilo
                koji drugi način otkrivamo podatke o ličnosti prikupljene na
                gore navedeni način, osim kako je opisano u ovoj Izjavi o
                privatnosti.
              </p>
            </li>
            <li>
              <p>
                Podatke o ličnosti kao što su korisničko ime, broj mobilnog
                telefona i adresu za dostavu, delimo sa našim partnerima koji
                nude proizvode iz svog asortimana preko Platforme, s obzirom da
                je isto neophodno u cilju izvršenja ugovora koji ste sklopili sa
                njima putem Platforme, a kako smo naveli, isto vršimo kao
                obrađivač podataka o ličnosti.{" "}
              </p>
            </li>
            <li>
              <p>
                S obzirom da smo angažovali nezavisnog provajdera usluga "BinBox
                Global Services SRL" koji vrši hostovanje i skladištenje ove
                platforme u naše ime, isti se smatra obrađivačem u smislu
                propisa iz oblasti zaštite podataka o ličnosti, a time i
                primaocem podataka o ličnosti. Ovaj pružalac usluga, odnosno
                primalac podataka, nije ovlašćen da koristi ili otkriva Vaše
                podatke o ličnosti.
              </p>
            </li>
            <li>
              <p>
                Ukoliko pristanete na obradu Vaših podataka u analitičke,
                statističke svrhe i marketinške svrhe, podatke o ličnosti delimo
                i sa kompanijama Google Inc. i MetaPlatforms Inc. budući da
                njihove alate koristimo u navedene svrhe.
              </p>
            </li>
            <li>
              <p>
                Ukoliko Vaše postupanje na Platformi ima elemente nedopuštenih
                aktivnosti ili krivičnog dela, Vaše podatke o ličnosti možemo
                podeliti i sa nadležnim državnim organima u cilju sankcionisanja
                takvog ponašanja i/ili krivičnog gonjenja.
              </p>
            </li>
            <li>
              <p>
                Najzad, ukoliko eventualno budemo delili Vaše podatke o ličnosti
                još nekom primaocu, uvek ćemo osigurati da isti postupaju prema
                našim uputstvima, uz primenu najviših standarda zaštite podataka
                o ličnosti, a ovu Izjavu o privatnosti ćemo ažurirati kako biste
                se i Vi upoznali sa kime sve smo podelili Vaše podatke i zbog
                čega.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            PRENOS PODATAKA O LIČNOSTI U DRUGE DRŽAVE
          </li>
          <ol type="1">
            <li>
              <p>
                Budući da se provajder usluga "BinBox Global Services SRL" koga
                smo angažovali nalazi van teritorije Republike Srbije, tačnije
                na teritoriji Republike Rumunije, kao i da kompanija čiju smo
                aplikaciju odabrali za korišćenje u cilju ostvarivanja naših
                ciljeva koristi alate od strane kompanija Google LLC i Meta
                Platforms Inc., čiji se serveri nalaze u Irskoj, Velikoj
                Britaniji i Sjedinjenim Američkim Državama, vršimo prenos u
                drugu državu i to na osnovu primerenog nivoa zaštite podataka o
                ličnosti, s obzirom na to da se sve navedene države - Rumunija,
                Irska, Velika Britanija i Sjedinjene Američke Države, tačnije
                obe navedene kompanije sa sedištem u SAD-u se nalaze na Data
                Privacy Framework Listi, čime se sve navedene države, odnosno
                kompanije nalaze na listi država u kojima se smatra da je
                obezbeđen primereni nivo zaštite podataka o ličnosti.
              </p>
            </li>
            <li>
              <p>
                Ukoliko eventualno budemo prenosili Vaše podatke o ličnosti van
                teritorije Republike Srbije, preduzećemo sve neophodne korake
                kako bismo osigurali da Vam je obezbeđen minimum isti nivo
                zaštite podataka o ličnosti kao i u Republici Srbiji, odnosno da
                je prenos tih podataka o ličnosti u skladu sa svim pozitivnim
                propisima Republike Srbije, a ovu Izjavu o privatnosti ćemo
                ažurirati kako biste se i Vi upoznali gde vršimo prenos
                podataka.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            KOLIKO DUGO ČUVAMO PODATKE O LIČNOSTI
          </li>
          <ol type="1">
            <li>
              <p>
                Podatke o ličnosti koje obrađujemo na osnovu Vašeg pristanka,
                brišemo odmah nakon opoziva pristanka. Ukoliko se ti podaci
                obrađuju dodatno u neku drugu svrhu (recimo broj mobilnog
                telefona), u tom slučaju ćemo prestati da obrađujemo takve
                podatke o ličnosti u svrhu za koju je opozvan pristanak. Više o
                pristanku i načinu opoziva, možete videti u delu ove Izjave koja
                se odnosi na pristanak i opoziv pristanka.
              </p>
            </li>
            <li>
              <p>
                Kada obrađujemo Vaše podatke u cilju pružanja usluga koje
                Platforma nudi, odnosno izvršenja ugovora, kada obrišete Vaš
                korisnički nalog, a niste imali nijednu kupoprodaju preko naše
                Platforme, podatke o ličnosti brišemo u potpunosti. Sa druge
                strane, ukoliko ste imali porudžbine preko naše Platforme, kada
                ugasite Vaš nalog, podatke čuvamo još 3 godine u skladu sa
                opštim pravilima o zastarelosti potraživanja iz ugovora o
                prometu robe i usluga kao i potraživanja naknade za izdatke
                učinjene u vezi s tim ugovorima.
              </p>
            </li>
            <li>
              <p>
                Podatke koje obrađujemo u svrhu upravljanja i zaštite naše
                usluge, uključujući rešavanje problema, testiranje, održavanje
                sistema, podršku, sprečavanje zloupotreba, brišemo takođe odmah
                nakon brisanja korisničkog naloga, ako nije bilo nedozvoljenih
                radnji ili aktivnosti sa Vaše strane.{" "}
              </p>
            </li>
            <li>
              <p>
                Ukoliko primetimo da vršite zloupotrebu naše Platforme i usluge
                ili druge nedozvoljene radnje ili aktivnosti kako je definisano
                uslovima korišćenja, tada Vaše podatke čuvamo još 5 godina
                koliko iznosi apsolutni rok zastarelosti za naknadu štete, a
                ukoliko Vaše postupanje ima elemente krivičnog dela, tada
                podatke čuvamo u vremenu određenom za zastarelost krivičnog
                gonjenja.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            VAŠA PRAVA U VEZI SA OBRADOM PODATAKA O LIČNOSTI
          </li>
          <ol type="1">
            <li>
              <p>
                U vezi sa obradom podataka o ličnosti ostvarujete sledeća prava:
              </p>
              <ol type="i">
                <li>pravo na pristup, </li>
                <li>pravo na ispravku i dopunu, </li>
                <li>pravo na brisanje, </li>
                <li>pravo na ograničenje obrade, </li>
                <li>pravo na prenosivost podataka i</li>
                <li>
                  pravo na prigovor (kada se obrada Vaših podataka o ličnosti
                  zasniva na legitimnom interesu).{" "}
                </li>
              </ol>
            </li>
            <li>
              <p>
                Ukoliko podnesete prigovor, dužni smo da prekinemo sa obradom
                Vaših podataka o ličnosti, osim ako postoje zakonski razlozi za
                obradu koji pretežu nad Vašim interesima, pravima ili slobodama
                ili su u vezi sa podnošenjem, ostvarivanjem ili odbranom pravnog
                zahteva.
              </p>
            </li>
            <li>
              <p>
                Zahtev za ostvarivanje prava možete da podnesete na adresu
                elektronske pošte dpo@apoteka.rs, lično u našim prostorijama i
                putem pošte na adresu sedišta Extra Care Group.
              </p>
            </li>
            <li>
              <p>
                Istovremeno, u svakom trenutku možete podneti pritužbu
                Povereniku ako smatrate da obrada podataka o ličnosti nije u
                skladu sa važećim Zakonom. Kontakt podaci Poverenika su: Bulevar
                kralja Aleksandra br. 15, Beograd, adresa elektronske pošte
                office@poverenik.rs, telefon:+381 11 3408 900
              </p>
            </li>
            <li>
              <p>
                Podnošenje pritužbe Povereniku ne utiče na mogućnost da zaštitu
                svojih prava ostvarite pokretanjem odgovarajućeg sudskog ili
                upravnog postupka.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>PITANJA ILI NEDOUMICE</li>
          <ol type="1">
            <li>
              <p>
                Za sva pitanja i nedoumice, odnosno za potrebe ostvarivanja
                vaših prava, možete se obratiti našem imenovanom Licu za zaštitu
                podataka o ličnosti, putem adrese elektronske pošte
                dpo@apoteka.rs.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>
            IZMENE IZJAVE O PRIVATNOSTI
          </li>
          <ol type="1">
            <li>
              <p>
                Extra Care Group zadržava pravo da po sopstvenom nahođenju
                ažurira, dopunjava, menja i/ili prilagođava ovu Izjavu o
                privatnosti.{" "}
              </p>
            </li>
            <li>
              <p>
                Ukoliko dođe do promene ove Izjave o privatnosti, bićete
                blagovremeno obavešteni o nastalim promenama kroz obaveštenje
                koje ćemo Vam poslati pre nego što takve izmene stupe na snagu.
              </p>
            </li>
          </ol>
          <li className={legalDocumentStyles.Headers}>ZAVRŠNE ODREDBE</li>
          <ol type="1">
            <li>
              <p>
                Ova Izjava o privatnosti je sastavljena na srpskom jeziku i biće
                tumačena u skladu sa pozitivnim propisima Republike Srbije.
              </p>
            </li>
            <li>
              <p>
                Ova Izjava o privatnosti se objavljuju na internet stranici
                Extra Care Group{" "}
                <a href="https://www.apoteka.rs/PrivacyPolicy">
                  https://www.apoteka.rs/PrivacyPolicy
                </a>
                , i počinje da se primenjuje u roku od 8 (osam) dana od dana
                objavljivanja.
              </p>
            </li>
            <li>
              <p>
                Poslednja verzija: 21.12.2024. godine. Prethodno važeću verziju
                od 29.08.2022. godine, možete pogledati na sledećem linku{" "}
                <a href="/m/PrivacyPolicyPrevious">ovde</a>.
              </p>
            </li>
          </ol>
        </ol>
        <p className={legalDocumentStyles.Headers}>
          OZNAČAVANJEM KUĆICE PORED IZJAVE O PRIVATNOSTI KORISNIK POTVRĐUJE DA
          JE ISTU PROČITAO I RAZUMEO SVE GORE POMENUTO{" "}
        </p>
      </div>
    </div>
  </Wrapper>
);

export default PrivacyPolicy;
